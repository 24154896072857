<header>

  <div class="nav-bar">
    <div class="new-container">
      <div class="new-head">
        <nav>
          <ul class="nav-links">
            <li><a href="https://www.facebook.com/KraftConstructionremodels"><img class="icon-nav"
                  src="../../../assets/facebook.png" alt=""></a></li>
            <li><a href="https://www.instagram.com/kraftremodels"><img class="icon-nav" src="../../../assets/insta.png"
                  alt=""></a></li>
          </ul>
        </nav>
        <div class="cta">
          <div class="cta-links">
            <a routerLink="/contact-us" class="pad"> <img class="cta-icon" src="../../../assets/Vector.png" alt=""></a>
            <div class="pad"><img src="../../../assets/Line 1.png"></div>
            <div class="pad">
              <a class="pad mt-2" routerLink="/contact-us">Contact us</a>
              <p class="black">tes</p>
            </div>
          </div>
          <div class="cta-links">
            <a class="pad" href="#"><img class="cta-icon" src="../../../assets/Vector (1).png" alt=""></a>
            <div class="pad"><img src="../../../assets/Line 1.png"></div>
            <div class="email   pad">
              <a href="Kraftconstructionco@kraftconstructionco.com">Email Us</a><br>
              <a href="Kraftconstructionco@kraftconstructionco.com"
                id="email-style">Kraftconstructionco@kraftconstructionco.com</a>
            </div>
          </div>
          <div class="cta-links">
            <a class="pad" routerLink="/login"><img class="cta-icon" src="../../../assets/login.png" alt=""></a>
            <div class="pad"><img src="../../../assets/Line 1.png"></div>
            <div class="pad">
              <a class="marg" routerLink="/login">Sign in</a>
              <p class="black">test</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- for 2nd sec -->
  <div class="nav-bar-2">
    <div class="new-container">
      <div class="new-head">
        <div class="logo">
          <a routerLink="/home"><img class="img-logo" src="../../../assets/logo.png"></a>
        </div>
        <div>
          <div class="nav-links-2">
            <a routerLink="/about-us"> About Us </a>
            <a routerLink="/interior-remodelling">Interior Remodeling</a>
            <div class="drop-down">
              <button class="dropbtn" (click)="toggleDropdown2()"><img src="../../../assets/dropDown.png"
                  alt=""></button>
              <div *ngIf="isDropdownOpen2" class="drop-down-content1">
                <a *ngFor="let item of DropData" (click)="getOneService(item.id)">{{ item.serviceName }} </a>
              </div>
            </div>
            <a routerLink="/new-additions">New Additions</a>
            <div class="drop-down">
              <button class="dropbtn" (click)="toggleDropdown()"><img src="../../../assets/dropDown.png"
                  alt=""></button>
              <div *ngIf="isDropdownOpen" class="drop-down-content">
                <a *ngFor="let item of DropData2" (click)="getOneService1(item.id)">{{ item.serviceName }}</a>
              </div>
            </div>
            <a *ngIf="sliderData?.status" routerLink="/careers">Careers</a>
            <a routerLink="/testimonials">Testimonials</a>
            <a href="https://huntington.billeriq.com/ebpp/Kraft/">Payments</a>
            <a routerLink="/request-estimate">Request Estimate</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="mobile-nav">
    <input type="checkbox" name="" id="" class="check" (click)="isMobMenuOpened = !isMobMenuOpened">
    <ul class="menu-items" *ngIf="isMobMenuOpened">
      <li><a routerLink="/home">Home</a></li>
      <li><a routerLink="/about-us">About Us</a></li>
      <li><a routerLink="/contact-us">Contact Us</a></li>
      <li><a routerLink="/careers">Careers</a></li>
      <li><a routerLink="/testimonials">Testimonials</a></li>
      <li><a routerLink="/request-estimate">Request Estimate</a></li>
      <li><a [href]="'https://huntington.billeriq.com/ebpp/Kraft/'">Payments</a></li>
      <li class="drop-down1"><a routerLink="/interior-remodelling">Interior Remodeling</a>
        <div class="drop-down">
          <button class="dropbtn" (click)="toggleDropdown2()"><img src="../../../assets/dropDown.png" alt=""></button>
          <div *ngIf="isDropdownOpen2" class="drop-down-content1">
            <a *ngFor="let item of DropData" (click)="getOneService(item.id)">{{ item.serviceName }} </a>
          </div>
        </div>
      </li>
      <li class="drop-down2">
        <a routerLink="/new-additions">New Additions</a>
        <div class="drop-down">
          <button class="dropbtn" (click)="toggleDropdown()"><img src="../../../assets/dropDown.png" alt=""></button>
          <div *ngIf="isDropdownOpen" class="drop-down-content">
            <a *ngFor="let item of DropData2" (click)="getOneService1(item.id)">{{ item.serviceName }}</a>
          </div>
        </div>
      </li>
    </ul>
    <div class="ham-menu">
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
    </div>

    <div class="logo">
      <a routerLink="/home"><img class="img-logo" src="../../../assets/logo.png"></a>
    </div>
    <div class="d-flex align-items-center">
      <a class="pad" routerLink="/login"><img class="login-img" src="../../../assets/login.png" alt=""></a>
      <a class="signIn" routerLink="/login">Sign in</a>
    </div>
  </div>
</header>

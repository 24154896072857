<div class="admin-home">
    <form [formGroup]="bannerForm" (ngSubmit)="onSubmit1()">
        <section class="home-banner">
            <div class="new-container">
                <h2 class="main-head">Home</h2>
                <div class="card   card-shade">
                    <div class="card-body">
                        <div class="slideshow">
                            <div class="slideshow-container">
                                <div *ngFor="let imageLink of imageLink; let i = index" class="slide"
                                    [ngClass]="{ 'active': i === activeIndex }">
                                    <img [src]="imageLink.imageUrl" alt="Slide {{i}}">
                                </div>
                            </div>

                            <div class="slide-dots">
                                <span *ngFor="let image of images; let i = index" class="dot"
                                    [ngClass]="{ 'active': i === activeIndex }" (click)="setActiveIndex(i)"></span>
                            </div>
                            <div class="d-flex gap-3 mt-4">
                                <button type="button" class="video-btn">
                                    <label class="img-upld" for="file-inputt">+</label>
                                    <input type="file" id="file-inputt" class="input-file"
                                        (change)="onFileSelected1($event)" accept="image/*" multiple>
                                </button>
                                <div class="slideshow-thumbnails d-flex gap-3">
                                    <div *ngFor="let imageLink of imageLink; let i = index" class="thumbnail-item"
                                        [class.active]="activeIndex === i">
                                        <img class="thumbnail-image" [src]="imageLink.imageUrl" alt="Thumbnail Image"
                                            (click)="setActiveIndex(i)" onerror="this.src='../../../assets/error-image.jpg'">
                                        <div class="del-btn"><button type="button"
                                                class="delete-icon" (click)="deleteImage(imageLink.id)"><i
                                                    class="fa fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 class="card-head2 mt-2">Add Description</h3>
                            <input class="form-control" type="text" placeholder="add description"
                                formControlName="bannerDescription">
                            <div class="slider-wrape  ">
                                <label class="switch  ">
                                    <input type="checkbox" (click)="toggleLink()" formControlName="linkStatus">
                                    <span class="slider round"></span>
                                </label>
                                <h4 class="slider-head">Add Link</h4>
                            </div>
                            <input class="form-control" type="text" placeholder="add link" formControlName="bannerLink">
                        </div>
                        <div class="saveBtn  mb-3 mt-5">
                            <button type="submit" class="save-btn">save</button>
                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">

                            {{ errorMessage }}

                        </div>

                        <div class="alert alert-success" role="alert" *ngIf="successMessage">

                            {{ successMessage }}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
    <form [formGroup]="aboutUsForm" (ngSubmit)="onSubmit()">
        <section class="about-us">
            <div class="new-container">
                <div class="card   card-shade">
                    <div class="card-head">About us</div>
                    <div class="card-body">
                        <div class="card-body-wrape">
                            <div class="card-content">
                                <h3 class="card-head2">About us</h3>
                                <header class="inverted">
                                </header>
                                <div class="content" role="main">
                                    <ckeditor [editor]="Editor" formControlName="textEditor"
                                        data="<h1><b>LET'S BEGIN DESIGNING <br>YOUR DREAM HOME ... TODAY!</b></h1>
                                  <p>Kraft Construction is a family-owned business that was founded in Cincinnati, Ohio in 1971. Over the years we have evolved into a residential remodeling company dedicated to helping homeowners create their dream spaces with our individualized design approach and our dedicated employees working in your homes that have a combined 106 years of experience.</p>"
                                        (ready)="onReady($event)" (change)="onChange($event)">
                                    </ckeditor>
                                </div>
                                <h3 class="card-head2 mt-2">Add link</h3>
                                <input class="form-control" type="text" placeholder="add link"
                                    formControlName="addLink">
                            </div>

                            <div class="img-content">
                                <div class="img-box mb-3">
                                    <video *ngIf="fileURL  || videoLink" class="img-dyn" [src]="videoLink"
                                        controls></video>

                                </div>
                                <div class="file-upload cardbtn">
                                    <button class="video-btn" type="button">
                                        <label for="file-input" class="custom-button">Upload Video</label>
                                        <input type="file" id="file-input" class="input-file" (change)="
                                    onFileSelected($event)" accept="image/*,video/*">
                                    </button>
                                </div>
                                <div class="alert alert-danger" role="alert" *ngIf="errorMessagess">

                                    {{ errorMessagess }}

                                </div>

                                <div class="alert alert-success" role="alert" *ngIf="successMessagess">

                                    {{ successMessagess }}

                                </div>
                            </div>
                        </div>
                        <div class="saveBtn  mb-3 mt-5">
                            <button class="save-btn" type="submit">save</button>
                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">

                            {{ errorMessage }}

                        </div>

                        <div class="alert alert-success" role="alert" *ngIf="successMessage">

                            {{ successMessage }}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
    <section class="services">
        <div class="new-container">
            <div class="card  card-shade">
                <div class="card-head">Services</div>
                <div class="card-body">
                    <div class="drop-1 mb-3 mt-3">
                        <label class="card-head-services" for="inputField">Select Service</label> <br>

                        <select class="inputField mt-3 mb-3" (change)="onOptionSelected($event)"
                            [(ngModel)]="selectedOption">
                            <option value="interior">Interior Remodeling</option>
                            <option value="addition">New Addition</option>
                        </select>
                    </div>
                    <div class="check-button-wrape">
                        <div class="check-button" *ngFor="let services of service">
                            <input type="checkbox" id="file-input" class="input-check" [value]="services"
                                (click)="addServiceOnHomePage(services.id)">
                            <label class="check-btn">{{ services.serviceName }}</label>
                        </div>
                    </div>

                    <div class="services-parent">

                        <div class="services-wrap ">
                            <div *ngFor="let service of serviceData" class="component">

                                <div class="kitchen-1-icon"><img class="img-logo" alt=""
                                        src={{this.globalUrl+service?.afterImageUrl}} onerror="this.src='../../../assets/error-image.jpg'"></div>
                                <h3 class="custom-kitchens">{{ service.serviceName }}</h3>
                                <p class="simply-dummy-text">{{ service.description }}</p>
                                <a class="rectangle-group">
                                    <div class="read-more">Read more</div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="saveBtn  mb-3 mt-5">
                        <button type="button" class="save-btn" (click)="onServiceClick()">save</button>
                    </div>


                    <div class="alert alert-danger" role="alert" *ngIf="errorMessages">

                        {{ errorMessages }}

                    </div>

                    <div class="alert alert-success" role="alert" *ngIf="successMessages">

                        {{ successMessages }}

                    </div>
                </div>
            </div>
        </div>

    </section>
    <section class="testimonials">
        <div class="new-container">
            <div class="card card-shade">
                <div class="test-bord">
                    <div class="card-head">Testimonials</div>
                    <div class="slider-wrape  ">
                        <label class="switch  ">
                            <input type="checkbox" (click)="toggleCardBody()">
                            <span class="slider round"></span>
                        </label>
                        <h4 class="slider-head">Show</h4>
                    </div>
                </div>
                <div class="card-body" *ngIf="isCardBodyVisible">
                    <h2 class="our-clients-say">Our Clients Say</h2>
                    <div class="client-wrap  d-flex  gap-3 mb-4">
                        <div class="img-clientSec">
                            <img class="img-client" src="../../../assets/clientImg.png" alt="">
                        </div>
                        <div class="clientSec-cont">
                            <ng-container *ngFor="let item of testimonialData; let i = index">
                                <div class="form-test mb-3">
                                    <label class="mb-2">Heading</label>
                                    <input type="text" class="form-control mb-3" [(ngModel)]="item.heading">

                                    <label class="mb-2">Description</label>
                                    <textarea class="form-control form-control-lg mb-3" [(ngModel)]="item.description"
                                        placeholder="Enter a description..."></textarea>

                                    <label class="mb-2">Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="item.name">

                                    <div class="btn-wrap mt-3">
                                        <button class="add-btn" (click)="deleteForm(item,i)">Delete</button>
                                        <button class="add-btn" (click)="addForm()">Duplicate</button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                    <div class="saveBtn  mb-5 mt-3">
                        <button (click)="onSubmit2()" class="save-btn">save</button>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">

                        {{ errorMessage }}

                    </div>

                    <div class="alert alert-success" role="alert" *ngIf="successMessage">

                        {{ successMessage }}

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
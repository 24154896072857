<section class="footer-section">
  <div class="footer-bacground">
    <div class="group">
      <img class="img-logo" alt="hey" src="../../../assets/logo.png" />
      <iframe class="logo-2" border="0" frameborder="0" style="border: 0;  height:70px; width:300px;"
        src="https://seal-cincinnati.bbb.org/frame/blue-seal-293-61-bbb-498.png?chk=05F971B5EC"></iframe>
    </div>
    <div class="new-container">


      <div class="footer-res">
        <div class="about-us-wrape">

          <div routerLink="/about-us" class="about-us">About Us</div>
          <div routerLink="/interior-remodelling" class="about-us">Interior Remodeling</div>
          <div routerLink="/new-additions" class="about-us">New Additions</div>
          <div routerLink="/careers" class="about-us">Careers</div>
          <div routerLink="/testimonials" class="about-us">Testimonials</div>
          <div routerLink="/payment" class="about-us">Payments</div>
          <div routerLink="/contact-us" class="about-us">Contact us</div>
        </div>

        <div class="footer-line"></div>

        <div class="footer-detail-wrape">
          <div class="footer-text">
            © 2000 to 2023 Kraftconstruction. All rights reserved.
          </div>
          <div class="footer-links-wrape">
            <div class="footer-side">Terms</div>
            <div class="footer-side">Privacy</div>
            <div class="footer-side">Cookies</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-header></app-header>
<div class="careers-page">
    <section class="banner-careers">
        <div class="new-container">
            <div class="banner-content">
                <h1>Careers</h1>
                <div class="test-nav">
                    <a href="">Home</a>
                    <a href=""><img src="../../../assets/Vector 9.png" alt=""></a>
                    <a href="">Careers</a>
                </div>
            </div>
        </div>

    </section>
    <form [formGroup]="userDetail">
        <section class="career-form-section">
            <div class="new-container">
                <h2 class="career-head">Please fill in your information below</h2>
                <div class="career-content-wrape">
                    <!-- forms -->

                    <div class="careers-form">
                        <div class="grid-container1">
                            <div class="form-group">

                                  <label class="mb-2" class="form-label" for="firstName">First Name</label>
                                  <input type="name" formControlName="firstName" class="form-control" id="firstName">

                                <div *ngIf="getControl['firstName'].touched && getControl['firstName'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['firstName'].errors?.['required']">First Name is required
                                    </div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" for="lastName" class="form-label">Last Name</label>
                                <input type="lastname" formControlName="lastName" class="form-control" id="lastName">
                                <div *ngIf="getControl['lastName'].touched && getControl['lastName'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['lastName'].errors?.['required']">Last Name is required</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" for="email" class="form-label">Email</label>
                                <input type="email" formControlName="email" class="form-control" id="email">
                                <div *ngIf="getControl['email'].touched && getControl['email'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['email'].errors?.['required']">Email is required</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="phoneNumber">Phone Number</label>
                                <input type="phone" formControlName="phoneNumber" class="form-control" id="phoneNumber">
                                <div *ngIf="getControl['phoneNumber'].touched && getControl['phoneNumber'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['phoneNumber'].errors?.['required']">Phone Number is required
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="form-group text-form-area">
                            <label class="mb-2" for="inputAddress" class="form-label">Address</label>

                            <input ngx-google-places-autocomplete formControlName="address"
                                class="form-control form-control-lg" id="inputAddress" placeholder="1234 Main St"
                                (onAddressChange)="handleAddressChange($event)">
                            <div *ngIf="getControl['address'].touched && getControl['address'].invalid"
                                class="text-danger">

                                <div *ngIf="getControl['address'].errors?.['required']">Address is required</div>

                            </div>
                        </div>
                        <div class="grid-container2">
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="city">City</label>
                                <input type="text" formControlName="city" class="form-control" id="city">
                                <div *ngIf="getControl['city'].touched && getControl['city'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['city'].errors?.['required']">City is required</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="inputState">State</label>
                                <select id="inputState" class="form-select" formControlName="state">
                                    <option *ngFor="let state of states" [value]="state">{{ state }}</option>

                                </select>
                                <div *ngIf="getControl['state'].touched && getControl['state'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['state'].errors?.['required']">State is required</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2 form-label" for="zip">Zip/postal</label>
                                <input formControlName="zip" type="text" class="form-control" id="zip">
                                <div *ngIf="getControl['zip'].touched && getControl['zip'].invalid" class="text-danger">
                                    <div *ngIf="getControl['zip'].errors?.['required']">Zip is required</div>
                                    <div *ngIf="getControl['zip'].errors?.['pattern']">Zip should only contain numbers
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="form-group text-form-area">
                            <label class="mb-2" class="form-label" for="workExperience">Work Experience</label>
                            <textarea formControlName="workExperience" class="form-control form-control-lg"
                                id="workExperience" rows="5"></textarea>
                            <div *ngIf="getControl['workExperience'].touched && getControl['workExperience'].invalid"
                                class="text-danger">
                                <div *ngIf="getControl['workExperience'].errors?.['maxlength']">Work Experience should
                                    have at most {{ getControl['workExperience'].errors?.['maxlength'].requiredLength }}
                                    characters</div>
                            </div>
                        </div>
                        <div class="grid-container1">
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="inputJob">Part/Full Time</label>
                                <select id="inputJob" formControlName="jobType" class="form-select">
                                    <option value="Yes">Full-Time</option>
                                    <option value="No">Part-Time</option>
                                </select>
                                <div *ngIf="getControl['jobType'].touched && getControl['jobType'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['jobType'].errors?.['required']">Job Type is required</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" for="formGroupExampleInput">Do you have any work
                                    restrictions?</label>
                                <select class="form-control" formControlName="workRestrictions">
                                    <option *ngFor="let state of jobType" [value]="state">{{ state }}</option>
                                </select>
                                <div *ngIf="getControl['workRestrictions'].touched && getControl['workRestrictions'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['workRestrictions'].errors?.['required']">Work Restrictions
                                        is required</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="formGroupExampleInput">Do you have any hour
                                    restrictions?*</label>
                                <select class="form-control" formControlName="hoursRestrictions">
                                    <option *ngFor="let state of jobType" [value]="state">{{ state }}</option>
                                </select>
                                <div *ngIf="getControl['hoursRestrictions'].touched && getControl['hoursRestrictions'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['hoursRestrictions'].errors?.['required']">Hours Restrictions
                                        is required</div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="formGroupExampleInput">Do you have any
                                    skills that relates to this job?*</label>
                                <select class="form-control" formControlName="skills">
                                    <option *ngFor="let state of jobType" [value]="state">{{ state }}</option>
                                </select>
                                <div *ngIf="getControl['skills'].touched && getControl['skills'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['skills'].errors?.['required']">Skill is required</div>

                                </div>
                            </div>
                        </div>

                        <h2 class="head2">References</h2>
                        <div class="grid-container-form">
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="Name">Name</label>
                                <input type="name" formControlName="references" class="form-control" id="Name">
                                <div *ngIf="getControl['references'].touched && getControl['references'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['references'].errors?.['required']">Name is required
                                    </div>

                                </div>
                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="Relationship">Relationship</label>
                                <input type="text" formControlName="referencesrel" class="form-control"
                                    id="Relationship">
                                <div *ngIf="getControl['referencesrel'].touched && getControl['referencesrel'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['referencesrel'].errors?.['required']">Relationship
                                        is
                                        required</div>

                                </div>
                            </div>

                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="phoneNumber">Phone</label>
                                <input type="phone" formControlName="referencesnum" class="form-control"
                                    id="phoneNumber">
                                <div *ngIf="getControl['referencesnum'].touched && getControl['referencesnum'].invalid"
                                    class="text-danger">

                                    <div *ngIf="getControl['referencesnum'].errors?.['required']">Phone Number
                                        is
                                        required</div>

                                </div>
                            </div>
                            <button (click)=" addForm()" class="career-btn">+</button>
                        </div>


                        <div *ngIf="addRow" class="row2 grid-container-form">
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="Name1">Name</label>
                                <input type="name" formControlName="references1" class="form-control" id="Name1">

                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="Relationship1">Relationship</label>
                                <input type="text" formControlName="referencesrel1" class="form-control"
                                    id="Relationship1">

                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="phoneNumber1">Phone</label>
                                <input type="phone" formControlName="referencesnum1" class="form-control"
                                    id="phoneNumber1">

                            </div>
                            <button (click)=" addForm2()" class="career-btn">+</button>
                        </div>
                        <div *ngIf="addRow2" class="row2 grid-container-form">
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="Name2">Name</label>
                                <input type="name" formControlName="references2" class="form-control" id="Name2">
                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="Relationship2">Relationship</label>
                                <input type="text" formControlName="referencesrel2" class="form-control"
                                    id="Relationship2">
                            </div>
                            <div class="form-group">
                                <label class="mb-2" class="form-label" for="phoneNumber2">Phone</label>
                                <input type="phone" formControlName="referencesnum2" class="form-control"
                                    id="phoneNumber2">
                            </div>
                        </div>


                        <div class="form-group text-form-area">
                            <label class="mb-2" class="form-label" for="otherNotes">Other Notes</label>
                            <textarea formControlName="otherNotes" class="form-control form-control-lg" id="otherNotes"
                                rows="5"></textarea>
                            <div *ngIf="getControl['otherNotes'].touched && getControl['otherNotes'].invalid"
                                class="text-danger">
                                <div *ngIf="getControl['otherNotes'].errors?.['maxlength']">Work Experience should have
                                    at most {{ getControl['otherNotes'].errors?.['maxlength'].requiredLength }}
                                    characters</div>
                            </div>
                        </div>
                        <div class="file">
                            <h3 class="mini-head" class="form-label">Resume Upload </h3>
                            <input type="file" formControlName="resume" class="form-control-file" id="resume"
                                (change)="onFileSelected($event)">
                            <div *ngIf="getControl['resume'].touched && getControl['resume'].invalid"
                                class="text-danger">

                                <div *ngIf="getControl['resume'].errors?.['required']">Resume is required</div>

                            </div>
                        </div>
                        <h3 class="captcha-head">CAPTCHA</h3>

                        <div class="captcha-content">
                            <div class=" wrape-robot">
                                <input type="checkbox" class="larger-checkbox">
                                <p class="mini-head"> I am not a robot</p>
                                <img src="../../../assets/recaptcha.png" alt="">
                            </div>
                        </div>
                        <button id="btn" class="button"  (click)='subitData()' >
                            <button type="submit"class="learn-more"
                                [disabled]="!userDetail.valid">Submit</button>

                        </button>
                        <div class=" message">
                            <div class="alert alert-success" role="alert" *ngIf="successMessage">
                                {{ successMessage }}
                            </div>

                            <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                                {{ errorMessage }}
                            </div>
                        </div>
                    </div>
                    <!-- boxes -->
                    <div class="green-contruct-box">
                        <div class="content6">
                            <h2 class="div3">2005+</h2>
                            <h3 class="completed-projects">Completed Projects</h3>
                        </div>
                        <div class="content5">
                            <h2 class="div3">50+</h2>
                            <h3 class="completed-projects">Years of Experience</h3>
                        </div>
                    </div>
                </div>
                <div class="rectangle-parent1">
                    <div class="green-box-wrape">
                        <p class="let-begin-designing">
                            Looking for a affordable and reliable construction
                            company for your project?
                        </p>
                        <a routerLink="/request-estimate" class="get-a-quote">Get a Quote</a>
                    </div>
                </div>
            </div>

        </section>
    </form>
</div>
<app-footer></app-footer>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
;

@Injectable({
  providedIn: 'root'
})
export class dashboardService {

  getAllUrl:string;
  apiUrl= 'https://api.kraftconstructionco.com/api';
  constructor(private http : HttpClient) { 
  
  this.getAllUrl=this.apiUrl+"/dashboard";


}

getAll():Observable<any> {
  return this.http.get<any>(this.getAllUrl);
  }
  
}
<app-header></app-header>
<main *ngIf="slides.length > 0">
  <!-- Banner Section -->
  <section class="banner-section slide" [style.background-image]="'url(' + slides[currentSlide] + ')'" [style.transform]="currentSlide === currentSlide ? 'translateX(0)' : 'translateX(100%)'">
    <div *ngIf="sliderData?.status" class="headline" [style.background]="sliderData.backgroundColor"
      [style.color]="sliderData.textColor">
      <marquee>{{ sliderData?.description }}</marquee>
    </div>
    <div class="new-container">
      <div class="slider">
        <h1 class="construction-solution">
          <!-- Remodeling for<br>your home -->
          {{ bannerDescription?.bannerDescription }}
        </h1>
        <a class="button" routerLink={{bannerDescription?.bannerLink}}>
          <div class="learn-more">Learn more</div>
          <div class="long-arrow-alt-right"><img class="img-logo" src="../../../assets/long-arrow-alt-right.png" alt="">
          </div>
        </a>
      </div>
    </div>
  </section>
  <!-- Construct Section -->
  <section class="Construct-section">
    <div class="new-container">
      <div class="main-construct-wrap">
        <div class="content5">
          <h2 class="div3">+2005</h2>
          <h3 class="completed-projects">Completed Projects</h3>
        </div>
        <div class="content5">
          <h2 class="div3">50+</h2>
          <h3 class="completed-projects">Years of Expirience</h3>
        </div>

      </div>
    </div>
  </section>
  <!-- About Section -->
  <section class="About-section">
    <div class="new-container">
      <!-- about section content -->
      <div class="dream-home">
        <div class="content">
          <div class="apidata" [innerHTML]="apiData?.aboutusDescription"></div>
          <a id="btn" class="button" routerLink={{apiData?.aboutusLink}}>
            <a class="learn-more">Learn more</a>
            <div class="long-arrow-alt-right"><img class="img-logo" src="../../../assets/long-arrow-alt-right.png"
                alt="">
            </div>
          </a>
        </div>
        <!-- about section image -->
        <div class="images-icon">
          <video class="img-dyn" src={{this.globalUrl+apiData?.aboutusVideoUrl}} controls></video>
        </div>
      </div>
      <!-- green box -->
      <div class="rectangle-parent1">
        <div class="green-box-wrape">
          <p class="let-begin-designing">
            Looking for a affordable and reliable construction
            company for your project?
          </p>
          <a routerLink="/request-estimate" class="get-a-quote">Get a Quote</a>
        </div>
      </div>
    </div>
  </section>
  <!-- Services Section -->
  <section class="services-section">
    <div class="new-container">
      <!-- Our services section -->
      <div class="services-parent">
        <h2 class="our-services-include">Our Services Include</h2>
        <div class="services-wrap">
          <div *ngFor="let service of serviceData" class="component">
            <div class="kitchen-1-icon"><img class="img-logofor" alt="" src={{this.globalUrl+service?.afterImageUrl}} onerror="this.src='../../../assets/error-image.jpg'">
            </div>
            <h3 class="custom-kitchens">{{ service.serviceName }}</h3>
            <p class="simply-dummy-text">{{ service.description }}</p>
            <a class="rectangle-group">
              <a routerLink="/interior-remodelling" class="read-more">Read more</a>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Our Client Section -->
  <section class="client-section">
    <div class="new-container">
      <h2 class="our-clients-say">Our Clients Say</h2>
      <div class="component-13">
        <div class="img-icon"><img class="img-test" alt="" src="../../../assets/client3.png" /></div>
        <div class="slide-container">
          <div class="bg6"><img id="eyes-img" class="img-logo" src="../../../assets/eyes.png" alt=""></div>
          <ng-container *ngFor="let object of objects; let i = index">
            <div *ngIf="i === activeSlideIndex" class="slide-item  client-Sec-Para">
              <!-- Render your object data here -->
              <div class="heading mb-3">{{ object.heading }}</div>
              <div class="description mb-3">{{ object.description }}</div>
              <div class="katy-grey">{{ object.name }}</div>
            </div>
          </ng-container>
          <div class="dot-indicators">
            <span *ngFor="let object of objects; let i = index" [class.active]="i === activeSlideIndex"
              (click)="setActiveSlide(i)">
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- special project Section -->
  <section class="special-project-section">
    <div class="new-container">
      <h2 class="our-special-projects">Our Special Projects</h2>
      <div class="rows-wrape">
        <div class="project-row">
          <div class="project-img"><img class="img-logo-project" alt="" src="../../../assets/service-1.png" /></div>
          <div class="project-img"><img class="img-logo-project" alt="" src="../../../assets/service-2.png" /></div>
          <div class="project-img"><img class="img-logo-project" alt="" src="../../../assets/service-3.png" /></div>
        </div>
        <div class="project-row">
          <div class="project-img"><img class="img-logo-project" alt="" src="../../../assets/service-4.png" /></div>
          <div class="project-img"><img class="img-logo-project" alt="" src="../../../assets/service-5.png" /></div>
          <div class="project-img"><img class="img-logo-project" alt="" src="../../../assets/service-6.png" /></div>
        </div>
      </div>
    </div>
  </section>
  <!-- map Section -->
  <section class="map-section">
  </section>
  <!-- Contact section -->
  <section class="contact-section">
    <div class="new-container">
      <div class="contact-wrape">
        <div class="contact-box">
          <h3 class="address-heading">Our Address</h3>
          <p class="contact-para">
            Kraft Construction Company,<br>
            1437 Compton road,<br>
            Cincinnati, Ohio 45231.
          </p>
        </div>
        <div class="contact-res">
          <div class="icon"><img class="img-logo-phone" alt="" src="../../../assets/phone-icon.PNG" /></div>
          <div class="company-phones">
            <ul>
              <li> Company Phones</li>
              <li class="contact-details">513.522.2414</li>
              <li class="contact-details">Fax:513.522.2411</li>
            </ul>
          </div>
        </div>
        <div class="contact-res">
          <div class="icon"><img class="img-logo-phone" alt="" src="../../../assets/msg-icon.PNG" /></div>
          <div class="company-phones">
            <ul>
              <li>kraft construction Emails</li>
              <li class="dustrogmailcom">Kraftconstructionco@kraftconstructionco.com</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>

export class Testimonial {
    id!: number;
    yourEmail : string= ''
    yourReview : string=''
    description : string=''
    workExperience:string=''
    yourName:string=''
    image:string=''
    page:string=''
    imageUrl:string=''
 
  }
<div class="dashboard">
    <section class="dashboard-section">
        <div class="new-container">
            <h2 class="main-head">Dashboard</h2>
            <div class="card card-shade">
                <div class="card-body">
                    <div class="content5-wrape">
                        <div class="content5">
                            <h2 class="div3">2005+</h2>
                            <h3 class="completed-projects">Completed Projects</h3>
                        </div>
                        <div class="content5">
                            <h2 class="div3">50+</h2>
                            <h3 class="completed-projects">Years of Experience</h3>
                        </div>
                    </div>
                    <div class="round-circles">
                        <div class="both-circle-wrape">
                            <div class="circle-wrape">
                                <div class="round">
                                    <p class="round-para">{{data?.requestEstimateCount}}</p>
                                </div>
                                <div class="circle-content">
                                    <h3 class="circle-head">Request Estimate</h3>
                                    <p class="circle-para">You have {{data?.requestEstimateCount}} new Request Estimate
                                    </p>
                                </div>
                            </div>
                            <div class="circle-wrape">
                                <div class="round">
                                    <p class="round-para">{{data?.testimonialCount}}</p>
                                </div>
                                <div class="circle-content">
                                    <h3 class="circle-head">Testimonials</h3>
                                    <p class="circle-para">You have {{data?.testimonialCount}} new Testimonials request
                                        for approvel</p>
                                </div>
                            </div>
                        </div>
                        <div class="circle-wrape">
                            <div class="round">
                                <p class="round-para">{{data?.careerApplicationCount}}</p>
                            </div>
                            <div class="circle-content">
                                <h3 class="circle-head">Job Applications</h3>
                                <p class="circle-para">You have {{data?.careerApplicationCount}} new job Applications
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
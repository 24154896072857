  <div class="home">
  <div class = "heading">
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <a class="navbar-brand">Contact Us</a>
    
     </div>
    </nav> 
  </div>
  <div class="desktop-3-item">
    <div class="alert alert-success" role="alert" *ngIf="successMessage1">
      {{ successMessage1 }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="errorMessage1">
      {{ errorMessage1 }}
    </div>
    <form [formGroup]="userDetails" (ngSubmit)="editAddress(userDetails.value)">
    <div class="col-10">
      <label for="inputAddress" class="form-label"style="margin-top:1rem;margin-left:1rem;">Address</label>
      <input type="text"  formControlName="address" class="form-control" id="inputAddress"style=
      "margin-left:1rem;">
    </div>
    <div class="saveBtn mb-3 mt-5">

      <button  class="save-btn" type="submit" >Edit</button>
   </div>
   </form>
    </div>
 </div>
 <div class="home1">
  <div class="alert alert-success" role="alert" *ngIf="successMessage">
    {{ successMessage }}
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
    <form [formGroup]="userDetail">
    
    <div *ngFor="let data of userdata" class="desktop-4-item">
      <div class="rectangle-container">
        <div class="group-item"></div>
        <div class="simply-dummy-text">
          {{data.message}}
        </div>
        <div class="group-div">
          <div class="group-inner"></div>
          <div class="email-parent">
            <div class="email">Email </div>
            <div class="oliverjohngmailcom">{{data.email}}</div>
          </div>
        </div>
        <div class="check-button">
          <div class="rectangle-parent1">
            <div class="group-child1"></div>
            <div class="name-parent">
              <div class="name">Name</div>
              <div class="oliver-john">{{data.firstname}}</div>
            </div>
          </div>
        </div>
        <div class="group-child2"></div>
        <div class="rectangle-parent2">
          <div class="group-child3"></div>
          <div class="parent">
            <div class="div">{{data.phonenumber}}</div>
            <div class="phone">Phone</div>
          </div>
          <div class="rectangle-parent3">
            <div class="group-child4"></div>
            <div class="country-parent">
              <div class="country">Country</div>
              <div class="usa">USA</div>
            </div>
          </div>
        </div>
        <div class="pm">{{ data?.createdDate | date:'yyyy-MM-dd' }} {{ data?.createdDate | date:'hh:mm' }}</div>
        <button (click)="deleteUser(data.id)" class="delete">Delete</button>
       
        
       
      </div>
    
    </div>

    </form>
    </div>
    
      
      
  
   
 
  
 
<div class="add-services">
    <form [formGroup]="interiorRemodForm" (ngSubmit)="onSubmit()">
        <section class="section-1">
            <div class="new-container">
                <div class="card  card-shade">
                    <div class="card-head">Services</div>
                    <div class="card-body">
                        <div class="service-wrape">
                            <div class="service-form">
                                <div class="form-group">
                                    <label class="label" for="formGroupExampleInput">Add Service</label>
                                    <input type="text" class="form-control mt-3 mb-3" id="formGroupExampleInput"
                                        placeholder="Interior remodeling" formControlName="addServiceName">
                                </div>
                                <div class="drop-1">
                                    <label class="label2" for="inputField">Select Service Page</label> <br>
                                    <select class="inputField mt-3 mb-3"
                                        formControlName="selectServicePage">
                                        <option *ngFor="let option of options" [value]="option">{{ option }}</option>
                                    </select>
                                </div>
                                <button class="custom-button" type="submit">Add now</button>
                                <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                                    {{ errorMessage }}
                                </div>
                                <div class="alert alert-success" role="alert" *ngIf="successMessage">
                                    {{ successMessage }}
                                </div>
                            </div>
                            <div>
                                <div class="drop-top">
                                    <label class="label2 mb-3" for="inputField">Select Page</label> <br>
                                    <select class="inputField" (change)="onOptionSelected($event)">
                                        <option hidden disabled selected value>Select an option</option>
                                        <option value="interior">Interior Remodeling</option>
                                        <option value="addition">New Addition</option>
                                    </select>
                                </div>
                                <div class="grey-box">
                                    <h3 class="label-box">Active Services</h3>
                                    <div *ngFor="let service of services" class="check-button">
                                        <div class="button-wrap">
                                            <div>
                                                <input type="checkbox" [checked]="service.active"
                                                    (change)="toggleCheckbox(service)">
                                                <label class="check-btn">{{ service.serviceName }}</label>
                                            </div>
                                            <button (click)="deleteOneService(service.id)" type="button" class="delete-icon"><i
                                                    class="fa fa-trash"></i></button>
                                        </div>
                                    </div>
                                    <div class="alert alert-danger" role="alert" *ngIf="errorMessages">
                                        {{ errorMessages }}
                                    </div>
                                    <div class="alert alert-success" role="alert" *ngIf="successMessages">
                                        {{ successMessages }}
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
    <section class="section-2">
        <div class="new-container">
            <div class="card  card-shade">
                <div class="card-body">
                    <div class="drop-wrape">
                        <div class="drop-2">
                            <label class="label2 mb-3" for="inputField">Select Page</label> <br>
                            <mat-select class="inputField" (valueChange)="onOptionSelected($event)">
                                <mat-option hidden disabled selected value>Select an option</mat-option>
                                <mat-option value="interior">Interior Remodeling</mat-option>
                                <mat-option value="addition">New Addition</mat-option>
                            </mat-select>
                        </div>
                        <div class="drop-2">
                            <label class="label2 mb-3" for="inputField">Select Service</label> <br>
                            <mat-select class="inputField" (valueChange)="getOneService($event)">
                                <mat-option *ngFor="let services of service" [value]="services?.id">{{ services?.serviceName
                                    }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <form [formGroup]="interiorRemodForm2">
                        <div class="img-wrape">
                            <div class="content1">
                                <div class="img-box">
                                    <img *ngIf="urllink || imageLink" class="img-dyn" [src]="imageLink" alt="">
                                    <input type="file" id="file-input" class="input-file"
                                        (change)="selectFiles($event)">
                                    <label for="file-input" id="file-input" class="upload-label"></label>
                                </div>
                                <div class="form-group">
                                    <label class="label mb-3" for="formGroupExampleInput">Add image tittle</label>
                                    <input type="text" class="form-control mb-3" placeholder="Before image"
                                        formControlName="beforeImage">
                                </div>
                                <div *ngIf="getControl['beforeImage'].touched && getControl['beforeImage'].invalid || (getControl['beforeImage'].untouched && isSubmitted)" class="error-text-red m-0">
                                  <mat-error *ngIf="getControl['beforeImage'].hasError('required')">Image Description Required</mat-error>
                                </div>
                            </div>
                            <div class="content1">
                                <div class="img-box">
                                    <img *ngIf="urllink2 || imageLink2" class="img-dyn" [src]="imageLink2" alt="">
                                    <input type="file" id="file-input1" class="input-file1"
                                        (change)="selectFiles2($event)">
                                    <label for="file-input1" id="file-input1" class="upload-label1"></label>
                                </div>
                                <div class="form-group">
                                    <label class="label mb-3" for="formGroupExampleInput">Add image tittle</label>
                                    <input type="text" class="form-control mb-3" placeholder="After image"
                                        formControlName="afterImage">
                                </div>
                                <div *ngIf="getControl['afterImage'].touched && getControl['afterImage'].invalid || (getControl['afterImage'].untouched && isSubmitted)" class="error-text-red m-0">
                                  <mat-error *ngIf="getControl['afterImage'].hasError('required')">Image Description Required</mat-error>
                                </div>
                            </div>

                        </div>
                        <div class="form-group  form-group-pad">
                            <label class="label2 mt-2 mb-3" for="exampleTextarea">Description</label>
                            <textarea class="form-control form-control-lg  mb-3" id="exampleTextarea" rows="5"
                                placeholder="Enter a description" formControlName="description"></textarea>
                            <div *ngIf="getControl['description'].touched && getControl['description'].invalid || (getControl['description'].untouched && isSubmitted)" class="error-text-red m-0">
                                  <mat-error *ngIf="getControl['description'].hasError('required')">Description Required</mat-error>
                             </div>
                        </div>
                        <div class="check-button1">
                            <input type="checkbox" class="input-check1" formControlName="isCheck"
                                (click)="toggleCardBody()">
                            <label class="check-btn1">Add Projects</label>
                        </div>
                    </form>
                    <div *ngIf="isCardBodyVisible">
                        <div id="grey-sec" class="card">
                            <!-- <div class="card-body">
                                <div class="drop-2">
                                    <label for="inputField" class="col-head mb-3">Select column</label> <br>
                                    <select class="inputField" [(ngModel)]="selectedQuantity">
                                        <option *ngFor="let option of quantityOptions" [value]="option">{{ option }}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                        </div>
                        <div class="card-wrape">
                            <div *ngFor="let card of cards.slice(0, selectedQuantity)" class="card-box">
                                <img class="card-img" [src]="card.imgSrc" alt="" onerror="this.src='../../../assets/error-image.jpg'">
                                <button class="file-upload cardbtn" type="button">
                                    <label for="file-input2{{card.id}}" class="custom-button-card">Upload Image</label>
                                    <input type="file" id="file-input2{{card.id}}" class="input-file"
                                        (change)="selectImage($event, card)">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="create-btn">
                        <button (click)="onClick1()" class="custom-button-create">
                            Save
                        </button>
                    </div>
                    <div class="alert alert-success" role="alert" *ngIf="successMessage">
                        {{ successMessage }}
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                        {{ errorMessage }}
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>

<app-header></app-header>

<div class="row">

  <div class="col">

    <div class="card text-bg-dark">
      <img class="card-img">
      <div class="card-img-overlay d-flex flex-column justify-content-center ">
        <div class="head">Contact Us</div>
        <div class="test-nav">
          <a href="">Home</a>
          <a href=""><img src="../../../assets/Vector 9.png" alt=""></a>
          <a href="contact-us">Contact us</a>
      </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    
    <div class="col-lg-5 col-md-8">
      <h3>Our friendly team would love to hear from you.</h3>
      <form [formGroup]="userDetail" (ngSubmit)="submit()" class="row g-4">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">First Name</label>
          <input type="text" formControlName="firstname" class="form-control" id="firstname" placeholder="First Name">
        
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">Last Name</label>
          <input type="text" formControlName="lastname" class="form-control" id="lastname" placeholder="Last Name">
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">Email Address</label>
          <input type="email" class="form-control" formControlName="email" id="email" placeholder="you@company.com">
        </div>
        <div class="col-md-8">
          <label for="phone" class="form-label">Phone Number</label>
          <div class="input-group mb-3">
            <select class="form-select" id="country-code"> <option *ngFor="let country of countryCodes" [value]="country.code"> {{ country.name }} ({{ country.code }}) </option> </select>
            <input type="tel" formControlName="phonenumber" class="form-control" id="phone" placeholder="+1(555)-000-000">
          </div>
        </div>
        <div class="col-12">
        <div class="form-group">
          <label for="message">Message</label>
          <textarea class="form-control" formControlName="message" id="message" name="message" rows="3" placeholder="Type your message here"></textarea>
      </div>
      </div>
      <div class="col-12">
          <button type="submit" class="btn btn-success">Send Message</button>
        </div>
        <div class="alert alert-success" role="alert" *ngIf="successMessage">
          {{ successMessage }}
        </div>
        
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </form>
    </div>
    <div class="col-lg-5 col-md-8">
        <div class="card1 d-flex shadow justify-content-center ">
            <div class="card-header"></div>
            <div class="card-body align-items-center ">
                <div class="card-title" style="border-top: 5px solid darkgreen;"></div>
                <h2>Our Address</h2>
                <h3 class="card-text ">
                  {{data?.address}}
                </h3>
            </div>
        </div>
        
    
        </div>
    </div>
    <div class="row">
    <div class="col-12">
      <h1 class="centre">Our Location</h1>
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe width="100%" height="90%" id="gmap_canvas" src="https://maps.google.com/maps?q=Kraft Construction Company 1437 Compton Road Cincinnati, OH 45231&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          <br>
          <style>
            .mapouter {
              position: relative;
              text-align: right;
              height: 0;
              width: 100%;
              padding-bottom: 60%;
            }
          </style>
          <style>
            .gmap_canvas {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 90%;
            }
          </style>
        </div>
      </div>
      
    </div>
</div>
</div>


  <app-footer></app-footer>



      
    

   
  
    
  


<app-header></app-header>
<div class="row">

  <div class="col">

    <div class="card text-bg-dark">

      <img class="card-img">

      <div class="card-img-overlay d-flex flex-column justify-content-center ">
        <div class="abtus">About Us</div>
        <div class="test-nav">
          <a href="">Home</a>
          <a href=""><img src="../../../assets/Vector 9.png" alt=""></a>
          <a href="about-us">About us</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-sm container-md container-lg ">
  <div class="row">
    <div class="col">
    <img class="mainImage" src={{globalUrl+aboutusData?.imageUrl}} alt="Uploaded Image"accept="image/*">
    </div>
    <div class="col">

      <div class="about-text">
        <h1>Our Mission: High Quality,<br>
          Fair Prices.</h1>
        <p class="aboutus2-1-icon"> About us</p>
        <div class="a">
          {{aboutusData?.description}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-d-flex">
  <div class="row">
    <div class="col">
      <div class="carousel-inner d-flex" style="height: 60vh;">
        <div class="carousel-item d-flex">
          <img id="my-img" src={{globalUrl+footerData?.footerImageUrl}} class="d-block w-100"alt="Uploaded Image"accept="footersImage/*">
          <div class="abc text-center d -flex">
            <p class=" get-a-quote ">Get a Quote</p>
            <h2 class="design-your-home">{{ footerData?.title  }} </h2>


            <button class="btn btn-success" routerLink="/contact-us">Appointment</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row">
  <div class="col">
    <div class="card2 sm-2" style="max-width: 240px;margin-left: 19rem; margin-top: 2rem;margin-bottom: 4rem;">
      <div class="row g-0">
        <div class="col-md-4">
          <img src="../../../assets/phone-icon.PNG" class="img-fluid rounded-start" alt="...">
        </div>
        <div class="col-md-7 ">
          <div class="card-body ">
            <h4 class="card-title" style="margin-left:0.1rem;">Company Phones</h4>
            <p class="card-text " style="margin-left:0.1rem;">513.522.2414.</p>
            <p class="card-text " style="margin-left:0.1rem;">Fax:<span>513.522.2411</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card2 mb-3" style="max-width: 240px;margin-top: 2rem; margin-bottom: 4rem;">
      <div class="row g-0">
        <div class="col-md-4">
          <img src="../../../assets/msg-icon.PNG" class="img-fluid rounded-start" alt="...">
        </div>
        <div class="col-md-2 ">
          <div class="card-body ">
            <h4 class="card-title" style="margin-left:0.5rem;">KRAFT<span>CONSTRUCTIONS</span></h4>
            <p class="card-text " style="margin-left:0.5rem;">EMAILS</p>
            <p class="card-text " style="margin-left:0.5rem;">Kraftconstructionco@kraftconstructionco.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<app-footer></app-footer>

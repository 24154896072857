
  <div class="home">
    <div class = "heading">
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <a class="navbar-brand">Testimonials Image</a>
      
       </div>
      </nav> 
    </div>
    <div class="desktop-3-item">
      <div class="alert alert-success" role="alert" *ngIf="successMessage">
        {{ successMessage }}
      </div>
 
      <div class="row">
        <div class="col">
            <img class="mt-4 form-img-1" [src]="testImage" controls 
            onerror="this.src='assets/admin-testimg/Group 160.png'">
            <!-- <ng-template #defaultImage>
                <img class="mt-3 form-img-1" [src]="'assets/admin-testimg/Group 160.png'">
            </ng-template> -->
            </div>
            <div class="col d-flex justify-content-center align-items-center ml-3">
            <div class="btn-group-vertical mt-3" role="group" aria-label="Vertical button group">
                <label for="file-input" class="custom-button ml-4">Upload Image</label>
                <input type="file" id="file-input" class="input-file" (change)="onFileSelected1($event)" accept="image/*">
                <button (click)="testimonialImage()" type="submit" class="btn btn-success btn3 mt-3">Save</button>
                <label (click)="deleteTestimonialImage(recentlyUploadedImage?.id)" class="custom-button1 mt-3">Delete Image</label>
            </div>
        </div>
        <div class="d-flex col-6 mx-auto justify-content-center align-items-center">
            <div class="d-grid gap-2 d-grid gap-2 col-6">
            <button (click)="content()" type="submit" class=" btn btn-success slider-button" *ngIf="hideButton">Add Slider</button>
            
            <div class="mt-3 me-5 group-child21" *ngIf="showSlider">
              <div class="card">
                <div class="card-header cardheader3 mt-3 ml-3">
                       <div class="a d-flex gap-3">
                        
                        <div  *ngFor="let sliderImage of sliderImages let i = index" class="slide" [ngClass]="{ 'active': i === activeIndex }">
                        <img class="user-icon1 mt-1" [src]="sliderImage" *ngIf="sliderImage; else defaultImage" alt="Slide {{i}}">
                        <ng-template #defaultImage>
                          <img class="user-icon1" [src]="'assets/admin-testimg/Group 160.png'">
                      </ng-template>
                        <div class="slider-text"> Slider {{i+1}}</div>
                      </div>
                      </div> 
                     
                      
                  </div>
           
                <div class="card-body">
                  <div class="btn-group-horizontal" role="group" aria-label="horizontal button group">
                    <label (click)="deleteSlider()" class=" abcd ml-1">Delete Image</label>
                    <label for="file-input1" class=" abc ml-2 mt-1">Upload Image</label>
                    <input multiple type="file" id="file-input1" class="input-file1" (change)="OnSliderSelected($event)" accept="image/*">
                   
                  </div>
                  <div class="btn-group-horizontal" role="group" aria-label="horizontal button group">
                    <button (click)="hideContent()" type="submit" class="btn btn-success btn2 mt-2">Cancel</button>
                    <button (click)="saveSliderImage()"type="submit" class="btn btn-success btn-large1 mt-2">Save</button>    
                
                  </div>
                  
                 
                 
                </div>
                <div class="alert alert-success" role="alert" *ngIf="successMessage3">
                  {{ successMessage3 }}
                </div>
              </div>
            </div>
        </div>
        </div>
    
      </div>
   
        </div>
          
    
      </div>
  
     

     <div class="home1">
        <div class = "heading">
          <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
              <a class="navbar-brand">Testimonials</a>
              
           </div>
          </nav> 
        </div>
        <div class="desktop-4-item">
          <div class="alert alert-success" role="alert" *ngIf="successMessage2">
            {{ successMessage2 }}
          </div>
          
         
          <div class="card" *ngFor="let usr of userdata">
            <div class="card-header card-header2 ">
              {{ usr?.createdDate | date:'yyyy-MM-dd' }} {{ usr?.createdDate | date:'hh:mm' }}
            </div>
            <div class="card-body">
              <div class="row">
       
              <img class="user-icon" alt="" src="../../../assets/admin-testimg/Group 160.png" />
             
              <div class="col">
              <h4 class="card-title">{{usr.yourReview}}</h4>
              <p class="card-text">{{usr.description}}</p> 
              <h4 class="card-title"style="font-size: var(--font-size-base);
              line-height: 26px;
              font-weight: 500;
              color: var(--kraft-d-green);">{{usr.yourName}}</h4>
              <div class="btn-container">
                <button (click)="deleteTestimonial(usr.id)" type="submit" class="btn btn-success btn1">Delete</button>  
                <button (click)="approveTestimonial(usr,usr.id)" type="submit" class="btn btn-success btn-large">Approve</button>

              </div>
              
            
            </div>
          </div>
        </div>
  </div>
  </div>
  <div class="space"></div>
  <div class="desktop-5-item" style="border-radius: 30%;">
    <div class="alert alert-success" role="alert" *ngIf="successMessage1">
      {{ successMessage1 }}
    </div>
  <div class="card" *ngFor="let usr of approvedata">
    <div class="card-header card-header1">
      {{ usr?.createdDate | date:'yyyy-MM-dd' }} {{ usr?.createdDate | date:'hh:mm' }}
    </div>
    <div class="card-body">
      <div class="row">

      <img class="user-icon mt-2" alt="" src="../../../assets/admin-testimg/Group 160.png" />
     
      <div class="col">
      <h4 class="card-title">{{usr.yourReview}}</h4>
      <p class="card-text">{{usr.description}}</p> 
      <h4 class="card-title"style="font-size: var(--font-size-base);
              line-height: 26px;
              font-weight: 500;
              color: var(--kraft-d-green);">{{usr.yourName}}</h4>
      <div class="container">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"  (change)="onCheckboxChange1(usr.id)">
          <label class="form-check-label" for="inlineCheckbox1">Delete</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" (change)="onCheckboxChange(usr,usr.id)">
          <label class="form-check-label" for="inlineCheckbox2">Hide</label>
        </div>
        
        

      </div>
      
    
    </div>
  </div>
</div>
</div>
</div>


</div>


<div class="side-menu">
  <div class="logo">
    <a routerLink="#"><img class="img-logo" src="../../../../assets/logo.png"></a>
  </div>
  <a class="dashboard" [routerLink]="['./dashboard']">Dashboard</a>
  <button class="dropbtn" [ngClass]="{'dropdown-open': isDropdownOpen}" (click)="toggleDropdown()">
    <div class="btn-wrape">
      <p class="">Pages</p>
      <img class="drop-icon" src="../../../assets/icon-drop.png" alt="">
    </div>
  </button>
  <div class="dropdown-content" [ngClass]="{'show': isDropdownOpen}">
    <a [routerLink]="['./admin-home']">Home</a>
    <a [routerLink]="['./admin-aboutUs']">About us</a>
    <a [routerLink]="['./career-news']">Add Careers News</a>
    <a [routerLink]="['./admin-careers']">Careers</a>
    <a [routerLink]="['./admin-contact-us']">Contact us</a>
    <a [routerLink]="['./admin-testimonial']">Testmonials</a>
    <a [routerLink]="['./admin-request']">Request Estimate</a>
  </div>
  <!-- button2 -->
  <button class="dropbtn" [ngClass]="{'dropdown-open': isDropdown2Open}" (click)="toggleDropdown2()">
    <div class="btn-wrape">
      <p class="">Services</p>
      <img class="drop-icon" src="../../../assets/icon-drop.png" alt="">
    </div>
  </button>
  <div class="dropdown-content" [ngClass]="{'show': isDropdown2Open}">
    <a [routerLink]="['./add-service']">Add Service</a>
    <a [routerLink]="['./pages']">Pages</a>
  </div>
  <!-- button4 -->
  <button class="dropbtn" [ngClass]="{'dropdown-open': isDropdown4Open}" (click)="toggleDropdown4()">
    <div class="btn-wrape">
      <p class="">User</p>
      <img class="drop-icon" src="../../../assets/icon-drop.png" alt="">
    </div>
  </button>
  <div class="dropdown-content" [ngClass]="{'show': isDropdown4Open}">
    <a [routerLink]="['./add-user']">Add new user</a>
    <a [routerLink]="['./showusers']">All users</a>
  </div>
</div>
<div class="service-page">
    <div class="new-container">
    <div class="card card-shade">
        <table class="table">
            <thead>
              <tr class="backgorund">
                <th class="width">Service name</th>
                <th >Author</th>
                <th class="desWidth">Description</th>
                <th class="width">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of tableData" [class.background]="row.index % 2 === 0">
                <td>{{ row.serviceName }}</td>
                <td>{{ row.author }}</td>
                <td>{{ row.description }}</td>
                <td>{{ row?.updateDate | date:'yyyy-MM-dd' }} {{ row?.updateDate | date:'hh:mm' }}</td>  
              </tr>
            </tbody>
          </table>
      </div>
    </div>
</div>

<div class="home">

  <div class="desktop-3-item">
    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
    <div class="alert alert-success" role="alert" *ngIf="successMessage">
      {{ successMessage }}
    </div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <a class="navbar-brand">Add New User</a>
  
        </div>
        
      
    </nav>
    <form [formGroup]="userDetail" (ngSubmit)="addUser()">
      <div class="row mb-3">
        <label for="name" class="col-sm-2 col-form-label">Username </label>
        <div class="col-sm-8">
          <input type="text"  formControlName="username" class="form-control" id="name" name="username" aria-describedby="emailHelp" >
          <div *ngIf="getControl['username'].touched && getControl['username'].invalid" class="text-danger">

            <div *ngIf="getControl['username'].errors?.['required']">User name is required</div>
  
          </div>
        </div>
    
      </div>
      <div class="row mb-3">
        <label for="email" class="col-sm-2 col-form-label">Email address</label>
        <div class="col-sm-8">
          <input type="email" formControlName= "email" class="form-control" id="email" name="email" aria-describedby="emailHelp" >
          <div *ngIf="getControl['email'].touched && getControl['email'].invalid" class="text-danger">

            <div *ngIf="getControl['email'].errors?.['required']">Email is required</div>
  
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label for="first-name" class="col-sm-2 col-form-label">First Name</label>
        <div class="col-sm-8">
          <input type="text" formControlName = "firstname" class="form-control" id="first-name" name="firstname" aria-describedby="emailHelp" >
          <div *ngIf="getControl['firstname'].touched && getControl['firstname'].invalid" class="text-danger">

            <div *ngIf="getControl['firstname'].errors?.['required']">firstname is required</div>
  
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label for="last-name" class="col-sm-2 col-form-label">Last Name</label>
        <div class="col-sm-8">
          <input type="text" formControlName = "lastname" class="form-control" id="last-name" name="lastname" aria-describedby="emailHelp" >
          <div *ngIf="getControl['lastname'].touched && getControl['lastname'].invalid" class="text-danger">

            <div *ngIf="getControl['lastname'].errors?.['required']">lastname is required</div>
  
          </div>
        </div>
      </div>
      
      
      <div class="row mb-3">
        <label for="password" class="col-sm-2 col-form-label">Password</label>
        <div class="col-sm-8 d-flex align-items-center">
          <input type="password" [type]="hidePassword ? 'password' : 'text'" formControlName="password" class="form-control" id="password">
          <i (click)="togglePassword()" class="fa ml-2" [ngClass]="{'fa-eye': !hidePassword, 'fa-eye-slash': hidePassword}"></i>
          <div *ngIf="getControl['password'].touched && getControl['password'].invalid" class="text-danger">

            <div *ngIf="getControl['password'].errors?.['required']">Password is required</div>
  
          </div>
        </div>
      </div>
      
      <div class="row mb-3">
        <label for="role"  class="col-sm-2 col-form-label">Role*</label>
        <div class="col-sm-8">
          <select class="form-select" formControlName="role" id="role">
            <option value="admin" >Admin</option>
            <option value="photographer">Photographer</option>
          </select>
          <div *ngIf="getControl['role'].touched && getControl['role'].invalid" class="text-danger">

            <div *ngIf="getControl['role'].errors?.['required']">Role is required</div>
  
          </div>
          
        </div>
      </div>
      <div class="row mb-3">
        <label for="profile-pic" class="col-sm-2 col-form-label">Profile Picture</label>
        <div class="col-sm-3">
          
            <img class="group-icon" [src]="profileImage? profileImage: 'assets/admin-testimg/Group 160.png' "/> 
            <input type="file" class="form-control" id="imageUrl" (change)="onFileSelected($event)"accept="image/*">
          </div>
          </div>
       
        <div class="col-sm-8">
          <button  type="button" class="btn btn-success" type="submit"> Add New User </button>  
        </div>
    </form>
   
   

  </div>
  
      
      
</div>
      
 

 


<!-- <h2 mat-dialog-title>Confirmation</h2> -->
<mat-dialog-content>{{ data.message }}</mat-dialog-content>
<mat-dialog-actions>
  <ng-container *ngIf="data.showYesNoButtons">
    <button mat-button [mat-dialog-close]="true">Yes</button>
    <button mat-button [mat-dialog-close]="false">No</button>
  </ng-container>
  <ng-container *ngIf="!data.showYesNoButtons">
    <button mat-button [mat-dialog-close]="data.customButton.action">
      {{ data.customButton.label }}
    </button>
  </ng-container>
</mat-dialog-actions>

<div class="root">
  <div class="container">
    <div class="col-sm-12 col-lg-5 justify-content-center align-items-center" >
      <div class="position-relative p-3 border rounded shadow-sm align-items-center">
        <div class="position-relative text-center">
          <img class="w-10" src="../../../assets/logimg/MicrosoftTeams-image (28) 2.png" alt="" />
          <h2 class="text-center mb-4" style="color: var(--kraft-d-green);font-family: var(--font-kanit);font-size:22px;">Kraft Construction</h2>
        </div>
        <form [formGroup]="loginform" (ngSubmit)="proceedlogin()">
        
          <div class="col mb-3"style="margin-left:22px;">
           
            <label for="username" class="form-label">User name</label>
            <input type="text" class="form-control" id="username" formControlName="username" placeholder="Enter Username">
          </div>
          <div class="col mb-3" style="margin-left: 22px;">
            <label for="password" class="form-label">Password</label>
            <div class="input-group">
              <input formControlName="password" type="password" type="{{ hidePassword ? 'password' : 'text' }}" id="password" class="form-control" placeholder="Enter password">
              <button class="btn btn-outline-secondary" type="button" (click)="togglePassword()">
                <i class="fa" [ngClass]="{'fa-eye': !hidePassword, 'fa-eye-slash': hidePassword}"></i>
              </button>
              
            </div>
          </div>
          
          <div class="form-group"style="margin-left:22px;">
          <button type="submit" class="btn btn-success"style="font-family: var(--text-sm-medium);font-size: 16px;">Login now</button>
          <div class="alert alert-danger" role="alert"style="width:90%;" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{ successMessage }}
          </div>
        </div>
      
        </form>
        
      </div>
    </div>
  </div>
</div>


<div class="flex-container">
<div class="home">
<div class = "heading">
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand">Users</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <button routerLink="../add-user" class="btn btn-success" type="button" >Add User</button>

      </div>
    </div>
  </nav> 
</div>

<div class="desktop-3-item">
  <div class="alert alert-success" role="alert" *ngIf="successMessage">
    {{ successMessage }}
  </div>
  
  <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
 <div class="table-responsive">
<table class="table">
<thead>   
     <tr>
        <th scope="col">ID</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Role</th>
        <th scope="col">Actions</th> 
      </tr>
      
    </thead>
    <tbody>
      <tr *ngFor="let usr of userdata">
        <td>
          <img class="abc" [src]="globalUrl + usr?.imageUrl" *ngIf="usr?.imageUrl; else defaultImage">
      </td>
      <ng-template #defaultImage>
          <img class="abc" [src]="'assets/admin-testimg/Group 160.png'">
      </ng-template>
       
        <td>{{usr.username}}</td>
        <td>{{usr.email}}</td>
        <th>{{usr.roles[0].name}}</th>
       
       
        <button data-bs-toggle="modal" data-bs-target="#editEmployee" mat-raised-button class="edit-button" (click)="editUser(usr)">
          <mat-icon>edit</mat-icon> Edit
        </button>
        <button mat-raised-button class="kraft-d-green-button" (click)="deleteUser(usr.username)">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
        
        
        
        
        
      
      </tr>
    
    </tbody>
  
  </table>

  <div class="modal fade" id="editEmployee" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="exampleModalLabel">Edit User</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

            <form [formGroup]="userDetail">
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">UserName </label>
                    <input type="text" name="username" formControlName="username" class="form-control" id="name" aria-describedby="emailHelp">
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Email Address </label>
                    <input type="email" name="email" formControlName="email" class="form-control" id="email" aria-describedby="emailHelp">
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">First Name </label>
                    <input type="text" name="firstname" formControlName="firstname" class="form-control" id="name" aria-describedby="emailHelp">
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Last Name </label>
                    <input type="text" name="lastname" formControlName="lastname" class="form-control" id="name" aria-describedby="emailHelp">
                  </div>
                
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Password </label>
                    <input type="text" formControlName="password" class="form-control" id="email" aria-describedby="emailHelp">
                  </div>
                  <div class="mb-3">
                    <label for="role" class="form control">Role*</label>
                    <div class="col-sm-8">
                      <select class="form-select" formControlName="role" id="role">
                        <option value="admin" [selected]="this.userDetail.value.role === 'ROLE_ADMIN'">Admin</option>
                        <option value="photographer" [selected]="this.userDetail.value.role === 'ROLE_PHOTOGRAPHER'">Photographer</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="profile-pic" class="col-sm-2 col-form-label">Profile Picture</label>
                    <div class="col-sm-3">
                      
                        <img class="group-icon" src= {{globalUrl+this.userDetail.value?.imageUrl}}/> 
                        <input type="file" class="form-control" id="imageUrl" (change)="onFileSelected($event)"accept="image/*">
                      </div>
                      </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button (click)=" updateUser()" type="button" class="btn btn-outline-success" data-bs-dismiss="modal">Update </button>
                  </div>
              </form>

        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>




</div>



<div class="admin-about-us">
    <div class="card-body ">
      <section class="first">
        <div class="new-container">
          <div class="alert alert-success" role="alert" *ngIf="successMessage1">
            {{ successMessage1 }}
          </div>
          
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage1">
            {{ errorMessage1 }}
          </div>
          <div class="card  card-shade">
            <div class="card-head">About us</div>
            <form [formGroup]="headData" (ngSubmit)="submit(headData.value)">
            <div class="content-wrape">
              <div class="img-content">
                <div class="img-box mb-3">
                  <img class="img-dyn" [src]="HeadImage" controls>
                </div>
                <div class="file-upload cardbtn">
                  <label for="file-input" class="custom-button">Upload Image</label>
                  <input type="file" id="file-input" class="input-file" (change)="onHeaderFileSelected($event)" accept="image/*">

                </div>
              </div>
              <div class="description">
                <h3 class="card-head2">About us</h3>
  
                <div class="textarea">
                  <textarea class="form-control form-control-lg  mb-3" formControlName="description" id="exampleTextarea" rows="5" placeholder="Enter description">
                   
                  </textarea>
                </div>
  
              </div>
              <div class="saveBtn mb-3 mt-5">
                <button  class="save-btn"> save</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </section>
      <section class="second">
    
        <div class="new-container">
          <div class="alert alert-success" role="alert" *ngIf="successMessage">
            {{ successMessage }}
          </div>
          
          <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>
          <div id="card-length" class="card card-shade ">
            <form [formGroup]="footerData" (ngSubmit)="submitFooterData()">
            <div class="content-wrap">
              <div class="img-content">
                <div class="img-box mb-3">
                  <img  class="img-dyn" [src]="FooterImage" controls>
                </div>
                <div id="green-button" class="file-upload cardbtn">
                  <label for="file-input1" class="custom-button">Upload Image</label>
                  <input type="file" formControlName="footerImage" id="file-input1" class="input-file" (change)="onFooterFileSelected($event)" accept="footerImage/*">

                </div>
              </div>
              <div class="form1">
                <input class="form-control" formControlName="title" type="text"  placeholder="Text on image" />
              </div>
              <div class="saveBtn mb-3 mt-5">
                <button class="save-btn" type="submit">save</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </section>
   
  </div>
